.projectsPageBox{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #e0e1dd;

    height: 100vh;

    /* background-color: #944545e4; */
}


.projectsHeader{
    /* font-family: 'Jersey 10 Charted'; */
    font-size: 4em;
}

.projectsBox{
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.project{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    background-color: #1B263B;
    border: 2px solid green;
    padding-bottom: 2vh;
    border-radius: 0%;
    color: #e0e1dd;
    font-weight: 550;
    font-size: 14px;
    

}

.descAndImages{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 10vw; */
}

.projectDesc{
    width: 90%;
    border: 2px solid black;
}

.projectImages{
    display: flex;
    /* grid-template-columns: 20vw 20vw 20vw 20vw; */
    /* column-gap: 4vw; */
    margin-left: 4vw;
    margin-right: 4vw;
    /* grid-auto-flow: row; */
    /* justify-content: center; */
    align-items: center;
    /* border: 1px solid black; */
    gap: 4vw;
    

}
.projectImage{
    
    width: 20vw;
    border-radius: 5%;
    
    height: auto;
}
.projectImage:hover{
    cursor: pointer;
}




.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 50px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  .modal-content {
    margin: auto;
    text-align: center;
  }
  
  #modal-image {
    display: inline-block;
    width: 75vw;
    height: auto;
  }
  

  /* The Close Button */
.close {
    color: #e0e1dd;
    display: block;
    font-size: 40px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    text-decoration: none;
    cursor: pointer;
  }


  ul{
    list-style: none;
    align-self: center;
    padding: 0;
  }

  li{
    color: #e0e1dd;
    text-decoration: none;
    
  }

  
  a:visited{
    color: #e0e1dd;
  }
  a:hover{
    color: black;
  }

  .collaboratorsBox{
    border: 2px solid purple;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }