.contentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.para{
    width: 80%;
}

.link{
    text-decoration: underline;
    
}

li{
    color: black;
}

li:not(:last-child) { 
    margin-bottom: 10px;  
 }