

.landingPage{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
h3{
    font-size: 1.5rem;
}

.topBox{
    /* border: 2px solid black; */
    width: auto;
    display: flex;
    flex-direction: column;
    
}

.bottomBox{
    /* border: 2px solid green; */
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.linkBox{
    display: flex;
    flex-direction: column;
    gap: 10px;
}


/*? IMAGE RULES */
img{
    width: 100%;
    height: 100%;
}
.portrait {
    height: 30vh;
    width: 30vh;
}

.landscape {
    height: 30px;
    width: 80px;
}

.square {
    height: 75px;
    width: 75px;
}

.shay{
    border-radius: 50%;
}


