.carousel{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 70vw;
    max-height: 40vh;

    margin-bottom: 5vh;
    margin-top: 10vh;
}

.slide{
    border-radius: 0.5rem;
    flex-shrink: 0;
    box-shadow: 0px 0px 7px #666;
    max-width: 70vw;
    max-height: 40vh;
    
}

.slide-hidden{
    display: none;

}

.arrow:hover{
    cursor: pointer;
    color: black;
}

.arrow{
    position: absolute;
    width: 2rem;
    height: 2rem;
    color: #a4a0a0;
    filter: drop-shadow(0px, 0px, 5px, #555);
    transition: 0.3s;
}

.arrow-left{
    left: 1rem;
}
.arrow-right{
    right: 1rem;
}

.indicators{
    display: flex;
    position: absolute;
    bottom: 1rem;
}

.indicator{
    background-color: white;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;
}
.indicator-inactive{
    background-color: grey;
}
