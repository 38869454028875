.aboutPageBox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* height: 90vh; */
}

.myPics{
    
}

.paragraph, .paragraph-me{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
}