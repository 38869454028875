.cont{
    /* display: flex; */
}

svg{
    /* border: 1px solid black; */
    /* width: 500px;
    height: 200px;  */
    width: 100%;
    height: 100%;

}
.nameBox{
    width: 100vw;
    height: 20vh;
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.path{

    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    width: fit-content;

    animation-timing-function: linear;
    animation-play-state: running;
    animation-duration: 8s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-name: draw;
}

@keyframes draw {
    from{
        stroke-dashoffset: 1000;
    } to {
        stroke-dashoffset: 700;
    }
}