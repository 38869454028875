@import './fonts.css';

.App {
  /* text-align: center; */
  /* font-family: 'Noto Serif'; */
  /* background-color: #e0e1dd; */
}
.appBox{
  /* display: flex; */
  /* flex-direction: column; */
}
body{
  font-family: 'Noto Serif';
  margin: 0;
}


.currentPage{
  /* height: 90vh; */
  /* width: 100vw; */
  /* border: 3px solid green; */
  /* background-color: #e0e1dd; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  
}

.App-link {
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainFooter{
  position: absolute;
  /* bottom: 100; */
}