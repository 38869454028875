@import './fonts.css';

.footerBox{
    /* display: flex; */
    /* justify-content: flex-end; */
    background-color: #ebebd3;
}

.Footer{
    height: 10vh;
    width: 100vw;
    background-color: #555;
    position: absolute;
    bottom: 0;

    display: flex;
    flex-direction: column;
}

.speechBox{
    /* display: flex;
    flex-direction: column;
    height: 100%; */
}

.image-me{
    /* position: absolute;
    left: 0;
    bottom: 0; */
    /* width: 30%; */
}

.meText{
    font-size: 2em;
    position: relative;
    right: 5%;
    bottom: 20%;
    /* translate: -40% -20%; */

}

h1{
    
    font-size: 5vh;
    position: absolute;
    left: 18vw;
    bottom: 10vh;

    font-family: 'Jacquard 12 Charted';
}