.linkBox{
  
}

.linkBoxText{
    /* color: black; */
    font-size: 24px;
      color: #000000;
      line-height: 2.2;
      /* text-transform: uppercase; */
      letter-spacing: 1.7px;
      text-decoration: none;

}

button {
    transition-duration: 0.4s;
    text-decoration: none;
    border: none;
    background-color:transparent;
    font-size: 4vh;
    font-family: 'Noto Serif';
}
  
button:hover {
    background-color: #3b4340; /* Green */
    text-decoration: none;
    color: white;
}

a{
    text-decoration: none;
}
