.Header{
    height: 12vh;
    width: 100vw;
    background-color: #555;
    position: top;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.headerName{
   /* position: relative; */
   /* left: 10; */
}



@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

body {
  font-family: 'montserrat',roboto;
}
header {
  width: 100%;
  height: 100%;
  background: #e74c3c;
  position: absolute;
  overflow: auto;
}

.icon-1, .icon-2, .icon-3 {
    position: absolute;
    left: 25%;
    top: 50%;
    width: 32px;
    height: 3px;
    background-color: black;
    transition: all 400ms cubic-bezier(.84,.06,.52,1.8);
}

.icon-1 {
  transform: translateY(-8px);
  animation-delay: 100ms;
}

.icon-3 {
  transform: translateY(8px);
  animation-delay: 250ms;
}

.hamburger-icon {
  position: absolute;
  height: 6vh;
  width: 6vh;
  top: 3vh;
  left: 3%;
  z-index: 1000;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  /* background: rgba(255,255,255,0.2); */
  /* // background: green;
  // width: auto;
  // height: 50px;
  // overflow: hidden;
  &:hover {
    // .icon-1 {
    //   transform: rotate(40deg);
    // }
    // .icon-3 {
    //   transform: rotate(-40deg);
    // }
    // .icon-2 {
    //   opacity: 0;
    // } */
    transform: scale(1.2);
    /* box-shadow: 0px 0px 30px rgba(0,0,0,0.1); */
  }
/* } */

.icon-1.a {
  transform: rotate(40deg);
}
.icon-3.b {
  transform: rotate(-40deg);
}
.icon-2.c {
  opacity: 0;
}

.clear {
  clear: both;
}

@keyframes slideIn {
  0% {
    width: 0%;
    height: 0%;
    /* // transform: scale(.3); */
    opacity: 0;
  }
  
  100% {
    height: 50%;
    width: 100%;
    /* // transform:scale(1); */
    opacity: 1;
  }
}

nav {
    background: #7c7f82;
  position: absolute;
  top: 0;
  left: 0;
  height: 60%;
  width: 0%;
  z-index: 10;
  opacity: 0;
  border-radius: 1%;
  /* border: 2px solid purple; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* pointer-events: none; */
 
    transition: all 600ms cubic-bezier(.62,.04,.3,1.56);
   transition-delay: 100ms;
  ul {
    margin: 0;
    position: absolute;
    /* top: 35%;
    left: 45%; */
    li {
      list-style: none;
      font-size: 24px;
      color: #fff;
      line-height: 2.2;
      text-transform: uppercase;
      letter-spacing: 1.7px;
      text-decoration: none;
      
      &:before {
        
      }
    }
  }
}

 nav.show {
  /* //  opacity: 0; */
   animation: slideIn 0.6s cubic-bezier(.62,.04,.3,1.56);
   animation-fill-mode: forwards;
   width: 100%;
   /* pointer-events: all; */
    /* // transform:scale(1); */
   opacity: 1;
}


.dark-blue {
  position: absolute;
  top: 0;
  left: 0;
  background: #7c7f82;
  height: 0%;
  width: 0%;
  transition: all 500ms cubic-bezier(.62,.04,.3,1.8);
  transition-delay: 50ms;
  z-index: 5;
  opacity: 1;
}

.dark-blue.slide {
  width: 0%;
  opacity: 0;
}

@keyframes shotup {
  0% {
    transform: translateY(300%) ;
    opacity: 0;
  }
  90% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0%) ;
    opacity: 1;
  }
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  h1 {
    font-size: 70px;
     animation: shotup 600ms ease-in-out;
    animation-delay: 300ms;
  }
}

.hamLink{
    font-size: 24px;
    color: #fff;
    line-height: 2.2;
    text-transform: uppercase;
    letter-spacing: 1.7px;
}

.linkList{
    /* border: 2px solid green;; */
}

.navBox{
    /* border: 2px solid green; */
}
